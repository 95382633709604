import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { Link, animateScroll as scroll } from 'react-scroll';
import moment from 'moment';
import PaymentComponent from '../razorpay/payment';
import { useLocation } from 'react-router-dom';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import './App.css';
import { useRef } from 'react';

import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import icon from './16.png'; 
import tickIcon from './circle.png';
import EntrepreneurshipClassesfetched from './EntrepreneurshipClassesfetched';





const handleSubmit = async (event) => {
  event.preventDefault(); // Prevent default form submission
  const formData = new FormData(event.target); // Create FormData object from form
  const requestData = Object.fromEntries(formData.entries()); // Convert FormData to object

  try {
    const response = await fetch('https://www.creativekidsworld.org/callBackDetails/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData) // Convert object to JSON string
    });

    if (response.ok) {
      // Handle success response
      console.log('Form data submitted successfully!');
      window.location.reload(); 
    } else {
      // Handle error response
      console.error('Failed to submit form data');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

  // Array of Grades from 1 to 12
  const Grades = Array.from({ length: 7 }, (_, i) => i + 6);

const CountriesOfWorld = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];
  const StatesOfIndia = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
	"Jammu and Kashmir",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
    
  ];
function Overlay({ handleClose }) {

  const [country, setCountry] = useState('');
const [showStateField, setShowStateField] = useState(false);
const handleCountryChange = (event) => {
  setCountry(event.target.value);
  if (event.target.value === 'India') {
    setShowStateField(true);
  } else {
    setShowStateField(false);
  }
};

  return (
    
    <div className="overlay"style={{
      position: 'fixed',
      top: '5%', // Adjust as needed
      left: '5%', // Adjust as needed
      zIndex: 9999
    }}>
      <button onClick={handleClose}style={{zIndex:9999}}>Close</button>
<div style={{ position: 'absolute', left: '5%', width: '50vw', height: '80vh', borderRadius: '20px', overflow: 'auto', marginTop:'4%',zIndex:9999}}>
        <div style={{  backgroundColor: 'rgba(0, 0, 0, 0.9)', padding: '20px', borderRadius: '20px' }}>
        <div style={{ backgroundColor: 'green', padding: '20px', textAlign: 'center' }}>
    <h2 style={{ color: '#fff', marginBottom: '20px', fontSize: '26px' }}>Fill In Your Details</h2>
    {/* Additional content or form can be added here */}
</div>

          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column',gap: '2px' ,color: '#fff' }}>
            <label style={{ width: '100%',color:'white' }}>
              Parent's Name:
              <input type="text" name="parentsName" style={{ borderRadius: '5px', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.75)', border: 'none',height:'25px' }} required />
            </label>
            <br />
            <label style={{ width: '100%' ,color:'white'}}>
              Kid's Name:
              <input type="text" name="kidName" style={{ borderRadius: '5px', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.75)', border: 'none',height:'25px'  }} required />
            </label>
            <br />
            <label style={{ width: '100%' ,color:'white'}}>
              Kid's Grade:
              <select name="kidGrade" style={{ borderRadius: '5px', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.75)', border: 'none',height:'25px'  }} required>
                <option value="" disabled selected>Select Grade</option>
                {Grades.map((grade) => (
                  <option key={grade} value={grade}>{grade}</option>
                ))}
              </select>
            </label>
            <br />
            <label style={{ width: '100%',color:'white' }}>
              Country:
              <select  onChange={handleCountryChange} name="Country" style={{ borderRadius: '5px', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.75)', border: 'none' ,height:'25px' }} required>
                <option value="" disabled selected>Select Country</option>
                {CountriesOfWorld.map((country, index) => (
                  <option key={index} value={country}>{country}</option>
                ))}
              </select>
            </label>
            <br />
            {showStateField && (
        <label style={{ width: '100%', color: 'white' }}>
          State:
          <select
            name="state"
            style={{
              borderRadius: '5px',
              width: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.75)',
              border: 'none',
              height: '25px'
            }}
            required
          >
            <option value="" disabled selected>
              Select State
            </option>
            {StatesOfIndia.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>
        </label>
      )}
      <br />
            <label style={{ width: '100%',color:'white' }}>
              Parent's Phone No.:
              <input type="tel" name="parentsPhone" style={{ borderRadius: '5px', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.75)', border: 'none',height:'25px'  }} required pattern="[0-9]{10}" title="Please enter a 10-digit phone number" />
            </label>
            <br />
            <label style={{ width: '100%' ,color:'white'}}>
              Parent's E-mail:
              <input type="email" name="parentsEmail" style={{ borderRadius: '5px', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.75)', border: 'none',height:'25px'  }} required />
            </label>
            <br />
            <button type="submit" style={{ borderRadius: '20px', backgroundColor: '#5E17EB', color: '#fff', width: 'auto', alignSelf: 'center', marginTop: '2%', padding: '10px', border: 'none', cursor: 'pointer',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onMouseOver={(e) => e.target.style.backgroundColor = '#4189E1'} onMouseOut={(e) => e.target.style.backgroundColor = 'royalblue'}>Submit</button>
          </form>
        </div>
      </div>
      
    </div>
  );
}

function Oform() {
  const [showOverlay, setShowOverlay] = useState(false);

  const handleOpenOverlay = () => {
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <div className="oform">
      {showOverlay && <Overlay handleClose={handleCloseOverlay} />}
      <button
        style={{
          position: 'fixed',
          bottom: '20px', // Adjust as needed
          right: '20px', // Adjust as needed
          zIndex:9999
        }}
        onClick={handleOpenOverlay}
      >
        Request a callback
      </button>
    </div>
  );
}


const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const ImageSlider = () => {
	const images = [
	  '27.png',
	  '28.png',
	  '29.png',
	  '30.png'
	];
	
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	useEffect(() => {
	  const interval = setInterval(() => {
		setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
	  }, 3000); // Change image every 3 seconds (adjust as needed)
  
	  return () => clearInterval(interval);
	}, [images.length]);
  
	const handleClickPrev = () => {
	  setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
	};
  
	const handleClickNext = () => {
	  setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
	};
  
	return (
	  <div style={{ width: '95%',margin: '0 auto', position: 'relative' }}>
		<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
		  <button style={{ fontSize: '24px', backgroundColor: '#5E17EB', border: 'none', padding: '8px', borderRadius: '5px' }} onClick={handleClickPrev}>{'<'}</button>
		  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
			{[currentImageIndex - 1, currentImageIndex, currentImageIndex + 1].map((index) => (
			  <div key={index} style={{ width: '33%',  padding: '8px', textAlign: 'center', marginRight: '1%',marginLeft:'1%' }}>
				<img
				  src={images[(index + images.length) % images.length]}
				  alt={`${index}`}
				  style={{
					maxWidth: '100%',
					maxHeight: '100%',
					borderRadius: '5px',
					transform: index === currentImageIndex ? 'scale(1.2)' : 'scale(1)',
					border: index === currentImageIndex ? '3px solid #5E17EB' : 'none'
				  }}
				/>
			  </div>
			))}
		  </div>
		  <button style={{ fontSize: '24px', backgroundColor: '#5E17EB', border: 'none', padding: '8px', borderRadius: '5px' }} onClick={handleClickNext}>{'>'}</button>
		</div>
	  </div>
	);
  };
	  
  const TextSlider = () => {
    const texts = ['Art of Storytelling', 'Logical Thinking', 'Self Esteem & Confidence building', 'Starting Conversations', 'Extempore Speech', 'Master Shyness'];
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [fontSize, setFontSize] = useState(18); // Initial font size
    const textRef = useRef(null);

    useEffect(() => {
        // Calculate font size based on available space
        const calculateFontSize = () => {
            if (textRef.current) {
                const maxWidth = textRef.current.offsetWidth;
                const textWidth = textRef.current.scrollWidth;
                const newFontSize = Math.floor(fontSize * (maxWidth / textWidth));
                setFontSize(newFontSize);
            }
        };

        calculateFontSize(); // Initial font size calculation

        const interval = setInterval(() => {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 3000); // Change text every 3 seconds (adjust as needed)

        // Recalculate font size on window resize
        window.addEventListener('resize', calculateFontSize);

        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', calculateFontSize);
        };
    }, [fontSize, texts.length]);

    const handleClickPrev = () => {
        setCurrentTextIndex((prevIndex) => (prevIndex - 1 + texts.length) % texts.length);
    };

    const handleClickNext = () => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    };

    return (
        <div style={{ width: '85%', margin: '0 auto', position: 'relative' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <button style={{ fontSize: '24px', backgroundColor: '#5E17EB', border: 'none', padding: '10px', borderRadius: '5px' }} onClick={handleClickPrev}>{'<'}</button>
                <div ref={textRef} style={{ width: '95%', display: 'flex', justifyContent: 'space-around' }}>
                    {[currentTextIndex - 1, currentTextIndex, currentTextIndex + 1].map((index) => (
                        <div key={index} style={{
                            width: '32%', border: '1px solid black', backgroundColor: index === currentTextIndex ? '#5E17EB' : 'transparent', padding: '10px', textAlign: 'center'
                        }}>
                            <h2 style={{ color: index === currentTextIndex ? 'white' : 'black', fontWeight: 'bold', fontSize: `${fontSize}px`, margin: '0' }}>{texts[(index + texts.length) % texts.length]}</h2>
                        </div>
                    ))}
                </div>
                <button style={{ fontSize: '24px', backgroundColor: '#5E17EB', border: 'none', padding: '10px', borderRadius: '5px' }} onClick={handleClickNext}>{'>'}</button>
            </div>
        </div>
    );
};
function EntrepreneurshipClass() {

  return (
    <div style={{ position: 'relative', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif' }}>
      <button
        style={{
          position: 'fixed',
          top: '22%',
          left: '2%',
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          backgroundColor: '#5E17EB',
          color: '#fff',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          zIndex:9999
        }}
        onClick={scrollToTop}
      >
        &uarr;
      </button>
	  <Header pagename='entrepreneurshippage'/>
    <h1 style={{ 
  textAlign: 'center',
 
  marginTop: '5%',
  background: '#5E17EB',
  color: 'white',
  width: '70%',
  marginLeft: '15%',
  fontFamily: 'Impact'
  , fontSize: 'calc(30px + 1vw)',
  wordSpacing: '10px' /* Added fontFamily for Impact */ 
}}>
  Entrepreneurship Program  For  21st  Century  Kids
</h1>

<h2 style={{ fontWeight: 'lighter', marginBottom: '20px', textAlign: 'center', fontFamily: 'Impact', fontSize: 'calc(25px + 1vw)' }}>
  Changing The World <span style={{ textDecoration: 'underline' }}>One Idea</span> at a Time
</h2>

      <div style={{ display: 'flex', textAlign: 'center', overflow: 'auto' ,alignItems:'center',justifyContent:'center'}}>
  <div style={{ width: '45vw', padding: '20px', alignItems: 'center', justifyContent: 'center' }}>
    <div style={{ padding: '20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '15px', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ backgroundColor: '#ffcccc', width: '21vw', height: '21vw', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ fontWeight: 'bold', margin: 0, fontSize: 'calc(10px + 1vw)' }}>Live<br/> Online<br/> Sessions</p>
      </div>
      <div style={{ backgroundColor: '#ccffcc', width: '21vw', height: '21vw', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
  <p style={{ fontWeight: 'bold', margin: 0, fontSize: 'calc(10px + 1vw)', textAlign: 'center', whiteSpace: 'normal', maxWidth: '100%', maxHeight: '100%', padding: '1px' }}>
    Generate & <span style={{ fontWeight: '900' }}>Own</span> Your Business Plans
  </p>
</div>



      <div style={{ backgroundColor: '#ccccff', width: '21vw', height: '21vw', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ fontWeight: 'bold', margin: 0, fontSize: 'calc(10px + 1vw)' }}>Free Study <br/> Material</p>
      </div>
      <div style={{ backgroundColor: '#ffffcc', width: '21vw', height: '21vw', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
  <p style={{ fontWeight: 'bold', margin: 0, fontSize: 'calc(10px + 1vw)', textAlign: 'center', whiteSpace: 'normal', maxWidth: '90%', maxHeight: '90%', padding: '1px' }}>
    Community <br/> & <br/> Corporate Access
  </p>
</div>


    </div>
  </div>
  <div style={{ width: '45vw', padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <img src="mainpage.png" alt="Image" style={{ maxWidth: '100%', borderRadius: '10px' }} />
  </div>
</div>


    <Oform />
    <div style={{}}>
  <h1 style={{ fontWeight: 'bold' }}>About the Program</h1>
  <h2 style={{ textAlign: 'center' }}>Entrepreneurship is not just a skill, but a mindset that can be nurtured from a young age. 
We are dedicated to empowering children with the knowledge, tools, and inspiration to become 
the innovators, leaders and most importantly, Creators of Tomorrow.</h2>
  <h2 style={{ marginTop: '5%', fontWeight: 'lighter',textAlign: 'center' }}>In this program, through our engaging and interactive classes and workshops children dive deep into fundamental business concepts, that ignites their imagination and empowers them to uncover real-world opportunities. By applying these principles, they cultivate the seeds of innovation, nurturing their own unique business ideas. Along the way, children not only enhance their analytical, creative, and problem-solving skills but also emerge as confident <span style={{ fontWeight: 'bold' }}>Leaders</span>  ready to tackle any challenge with passion and determination.</h2>
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10%', gap: '2%', alignItems: 'center', textAlign: 'center' }}>
  <div style={{ backgroundColor: '#ffcccc', width: '21vw',
    height: '23vw',
    padding: '1px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    textAlign: 'center', }}>
    <p style={{
      fontWeight: 'bold',
      margin: 0,
      fontSize: 'clamp(2px, 2vw, 24px)', // Adjusted to keep the text size within bounds
      maxWidth: '100%',
      maxHeight: '100%',
      textAlign: 'center',
      
      overflow: 'auto', // Prevent overflow
    }}>Duration: 10 Sessions <br/> 2 months <br/>1.25 Hour Session </p>
  </div>
  <div
  style={{
    backgroundColor: '#ccffcc',
    width: '21vw',
    height: '23vw',
    padding: '1px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    textAlign: 'center',
  }}
>
  <p
    style={{
      fontWeight: 'bold',
      margin: 0,
      fontSize: 'clamp(2px, 2vw, 24px)', // Adjusted to keep the text size within bounds
      maxWidth: '100%',
      maxHeight: '100%',
      textAlign: 'center',
     
      overflow: 'auto', // Prevent overflow
    }}
  >
    Group Details:<br />
    Group 1:<br />
    Grade 6 to Grade 8<br />
    Group 2:<br />
    Grade 9 to Grade 12<br />
    Group 3:<br />
    Undergraduates & Graduates
  </p>
</div>


  <div style={{ backgroundColor: '#ccccff', width: '21vw',
    height: '23vw',
    padding: '1px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    textAlign: 'center', }}>
  <p style={{
      fontWeight: 'bold',
      margin: 0,
      fontSize: 'clamp(2px, 2vw, 24px)', // Adjusted to keep the text size within bounds
      maxWidth: '100%',
      maxHeight: '100%',
      textAlign: 'center',
     
      overflow: 'auto', // Prevent overflow
    }}>
  Early Bird Price:<br/>
  Get 62% Off <br/> <span style={{ textDecoration: 'line-through', color: 'red' }}>₹25000</span> 
  <span style={{ color: 'red' }}> ₹9500</span><br/>
  Per Student*<br/>
  <span style={{fontSize:'clamp(2px, 1.5vw, 12px)'}}>*GST Included</span>
</p>

  </div>
  <div style={{ backgroundColor: '#ffffcc', width: '21vw',
    height: '23vw',
    padding: '1px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    textAlign: 'center', }}>
    <p style={{
      fontWeight: 'bold',
      margin: 0,
      fontSize: 'clamp(2px, 2vw, 24px)', // Adjusted to keep the text size within bounds
      maxWidth: '100%',
      maxHeight: '100%',
      textAlign: 'center',
      
      overflow: 'auto', // Prevent overflow
    }}>Batch size:<br/> 10 Students</p>
  </div>
</div>

</div>

      <div style={{ marginTop:'80px', marginBottom:'80px', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif' }}>
  <h1 style={{ textAlign: 'center',  marginBottom: '50px' }}>Detailed Session Plan</h1>
  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '20px',width:'80%',justifyContent: 'center', marginLeft:'10%',marginRight:'10%', overflow:'auto' }}>
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Session 1</h3>
      <h4>Introduction to Entrepreneurship</h4>
    </div>
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Session 2</h3>
      <h4>Design Thinking</h4>
    </div>
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Session 3</h3>
      <h4>Identifying Problems & Solutions</h4>
    </div>
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Session 4</h3>
      <h4>Understanding Customers</h4>
    </div>
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Session 5</h3>
      <h4>Developing Business Idea</h4>
    </div>
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Session 6</h3>
      <h4>Market Research</h4>
    </div>
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Session 7</h3>
      <h4>Prototyping</h4>
    </div>
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Session 8</h3>
      <h4>Marketing & Branding</h4>
    </div>
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Session 9</h3>
      <h4>Financial Literacy & Models</h4>
    </div>
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Session 10</h3>
      <h4>Pitch day, Reflection & Celebration</h4>
    </div>
  </div>
</div>

    <div style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', textAlign: 'center' }}>
      <h1 style={{ margin: '20px 0' ,fontWeight:'bold'}}>What You Will Learn In Our Entrepreneurship Program </h1>
      {/*<h3 style={{ width: '75%', margin: '0 auto', marginBottom: '30px',fontWeight:'normal',fontSize:'20px' }}>THIS ONLINE COURSE WILL HELP YOU TAKE YOUR ENGLISH LANGUAGE ABILITY, COMMUNICATION SKILLS AND OVERALL PERSONALITY TO THE NEXT LEVEL. YOU WILL LEARN :</h3>*/}
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '20px', marginBottom: '5%', justifyContent: 'center', marginLeft:'10%',marginRight:'10%' }}>
        <div style={{ display: 'flex', alignItems: 'center',gap: '10px' ,textAlign:'left' }}>
          <img src={tickIcon} alt="Tick Icon" style={{ width: '40px', height: '40px' }} />
          <div>
            <h3 style={{fontWeight:'bold'}}>Developing Business Ideas</h3>
            <h4 style={{ fontWeight: 'normal'}}>How to Develop your business ideas from scratch</h4>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center',gap: '10px' ,textAlign:'left' }}>
          <img src={tickIcon} alt="Tick Icon" style={{ width: '40px', height: '40px' }} />
          <div>
            <h3 style={{fontWeight:'bold'}}>Design Thinking</h3>
            <h4 style={{ fontWeight: 'normal' }}> Learn the 7 design Principles: Empathize, Define,Prototype, Test, Iterate, Implement.</h4>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center',gap: '10px' ,textAlign:'left'}}>
          <img src={tickIcon} alt="Tick Icon" style={{ width: '40px', height: '40px' }} />
          <div>
            <h3 style={{fontWeight:'bold'}}>Applying Creativity & Innovation</h3>
            <h4 style={{ fontWeight: 'normal' }}>Aptly applying or discarding fresh ideas based on merit and application in use cases.</h4>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center',gap: '10px' ,textAlign:'left' }}>
          <img src={tickIcon} alt="Tick Icon" style={{ width: '40px', height: '40px' }} />
          <div>
            <h3 style={{fontWeight:'bold'}}>Building Prototype</h3>
            <h4 style={{ fontWeight: 'normal' }}>Simulate and build prototypes to your business idea</h4>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center',gap: '10px' ,textAlign:'left' }}>
          <img src={tickIcon} alt="Tick Icon" style={{ width: '40px', height: '40px' }} />
          <div>
            <h3 style={{fontWeight:'bold'}}>Financial Planning & Literacy</h3>
            <h4 style={{ fontWeight: 'normal' }}>Learn the basics of financial literacy and how real world uses different revenue models.</h4>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center',gap: '10px' ,textAlign:'left' }}>
          <img src={tickIcon} alt="Tick Icon" style={{ width: '40px', height: '40px' }} />
          <div>
            <h3 style={{fontWeight:'bold'}}>Marketing Your Product/Service</h3>
            <h4 style={{ fontWeight: 'normal' }}> Learn about various marketing channels and how to use them to market your ideas.</h4>
          </div>
        </div>
      </div>
    </div>

  
  <div style={{ marginTop:'3%',fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', textAlign: 'center' }}>
  <h1 style={{  margin: '20px 0',fontWeight:'bold', marginBottom: '50px'}}>How And Where This Program Will Help Your Child</h1>
  
  {/* Grid container for pictures */}
  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '20px', marginBottom: '40px', marginLeft:'5%',marginRight:'5%'}}>
    <div>
        <img src="001.png" alt="Image 1" style={{ width: '200px',height:'200px', borderRadius: '10px' }} />
        <h3 style={{ marginTop: '10px', fontWeight:'bold' }}>Creativity & Innovation</h3>
    </div>
    <div>
        <img src="002.png" alt="Image 2" style={{ width: '200px',height:'200px', borderRadius: '10px' }} />
        <h3 style={{ marginTop: '10px', fontWeight:'bold' }}>Critical Thinking</h3>
    </div>
    <div>
        <img src="003.png" alt="Image 3" style={{width: '200px',height:'200px', borderRadius: '10px' }} />
        <h3 style={{ marginTop: '10px',  fontWeight:'bold' }}>Resilience & Grit</h3>
    </div>
    <div>
        <img src="004.png" alt="Image 4" style={{ width: '200px',height:'200px', borderRadius: '10px' }} />
        <h3 style={{ marginTop: '10px',  fontWeight:'bold' }}>Financial Literacy</h3>
    </div>
    <div>
        <img src="005.png" alt="Image 5" style={{ width: '200px',height:'200px', borderRadius: '10px' }} />
        <h3 style={{ marginTop: '10px',  fontWeight:'bold' }}>Communication & Collaboration</h3>
    </div>
    <div>
        <img src="006.png" alt="Image 6" style={{ width: '200px',height:'200px', borderRadius: '10px' }} />
        <h3 style={{ marginTop: '10px',fontWeight:'bold' }}>Confidence & Self-Esteem</h3>
    </div>
    <div>
        <img src="007.png" alt="Image 6" style={{ width: '200px',height:'200px', borderRadius: '10px' }} />
        <h3 style={{ marginTop: '10px',  fontWeight:'bold' }}>Entrepreneurial Mindset</h3>
    </div>
    <div>
        <img src="008.png" alt="Image 6" style={{ width: '200px',height:'200px', borderRadius: '10px' }} />
        <h3 style={{ marginTop: '10px', fontWeight:'bold' }}>Real-World Experience</h3>
    </div>
</div>

</div>
    <div style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', textAlign: 'center' }}>
      <h1 style={{ margin: '20px 0' ,fontWeight:'bold'}}>Program Plan</h1>
      <h3 style={{ marginBottom: '20px', fontWeight:'bold',background:'#5E17EB',width:'30%',marginLeft:'35%',color:'white' }}>{" "}
					<span style={{color:'yellow'}}>
          ★★ Early Bird Fee ★★
					</span>
					{" "}</h3>
      
					<EntrepreneurshipClassesfetched />
    </div>
    <div>
    <h1 style={{
  textAlign: 'center',
  marginTop: '3%',
  marginBottom: '5%',
  fontWeight: 'bold',
  textDecoration: 'underline' /* Adding underline */
}}>
  What You Get
</h1>

      <ImageSlider/>
    </div>
    <div style={{ marginTop:'5%',fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', textAlign: 'center' }}>
    <h1 style={{ margin: '20px 0' , fontWeight:'bold',marginBottom:'5%'}}>International Kids Entrepreneurship Challenge 2024</h1>
 
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '20px',width:'80%',justifyContent: 'center', marginLeft:'10%',marginRight:'10%', overflow:'auto' }}>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap:'5px'
        }}
      >
        <h4 style={{  }}>Winner: Most Innovative Idea <br/> <span style={{fontWeight:'lighter'}}>Team Antara from DPS Patna</span></h4>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/ysNSK5vUmuU?si=farvG4dsApfUUcNk"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
      
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap:'5px'
        }}
      >
        <h4 style={{  }}>Winner: Best Business Plan <br/> <span style={{fontWeight:'lighter'}}>Team Treller from DPS Patna</span></h4>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/lH3iJdoljDI?si=r4rspcKZpUHrRI05"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  
  </div>  

    <div style={{ marginTop:'5%',fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', textAlign: 'center' }}>
      <h1 style={{ margin: '20px 0' , fontWeight:'bold',marginBottom:'5%'}}>How We Work</h1>
      
      {/* Container for the grid */}
      <div style={{ marginLeft: '5%', marginRight: '5%', display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '25px', justifyContent: 'center' }}>
    {/* Box 1 */}
    <div style={{ padding: '20px', border: '2px solid #5E17EB', borderRadius: '10px', minWidth: '200px', textAlign: 'center' }}>
        <h4 style={{ fontWeight: 'bold' }}>Step 1</h4>
        <h3 style={{ fontSize: '18px' }}>Click on 'Request a Callback' button and fill in the details. Reserve your slot by Booking under Program Plan</h3>
    </div>

    {/* Box 2 */}
    <div style={{ padding: '20px', border: '2px solid #5E17EB', borderRadius: '10px', minWidth: '200px', textAlign: 'center' }}>
        <h4 style={{ fontWeight: 'bold' }}>Step 2</h4>
        <h3 style={{ fontSize: '18px' }}>Student ID & program details will be generated and shared via e-mail and whatsapp</h3>
    </div>

    {/* Box 3 */}
    <div style={{ padding: '20px', border: '2px solid #5E17EB', borderRadius: '10px', minWidth: '200px', textAlign: 'center' }}>
        <h4 style={{ fontWeight: 'bold' }}>Step 3</h4>
        <h3 style={{ fontSize: '18px' }}>Install Microsoft Teams (We will guide and support you)</h3>
    </div>

    {/* Box 4 */}
    <div style={{ padding: '20px', border: '2px solid #5E17EB', borderRadius: '10px', minWidth: '200px', textAlign: 'center' }}>
        <h4 style={{ fontWeight: 'bold' }}>Step 4</h4>
        <h3 style={{ fontSize: '18px' }}>Attend the classes as per your schedule</h3>
    </div>

</div>

    </div>
    <div style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', textAlign: 'center' ,marginBottom:'5%'}}>
      <h1 style={{  margin: '20px 0',fontWeight:'bold',marginBottom:'5%',marginTop:'5%', textDecoration: 'underline' }}>Frequently Asked Questions</h1>
      
      {/* FAQ divisions */}
      <div style={{ maxWidth: '75vw', margin: '0 auto' }}>
        {/* FAQ 1 */}
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Who will take the sessions?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>The sessions will be conducted by Industry experts, Ex and current founders and professionals who passed out from top IIMs, IITs, NITs. </h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        
        {/* FAQ 2 */}
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' ,marginTop:'30px'}}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>How long will each session be?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>The sessions will be conducted once a week online over Microsoft Teams. </h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        
        {/* FAQ 3 */}
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' ,marginTop:'30px' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Will the sessions be recorded and shared?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Every session will be recorded. However the recorded sessions will not be shared with the participants. Students will be shared a summary of class report at the end of each session. However, students are encouraged to take their own notes during the class.</h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        
        {/* FAQ 4 */}
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' ,marginTop:'30px'}}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Will study material be provided?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Yes, study material in soft copy format will be provided at the end of every session for the topics covered in class. This can later be referred by students for their personal learning development.</h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        
        {/* FAQ 5 */}
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' ,marginTop:'30px'}}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>What will be the timing of the classes?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>The timing of the classes for each batch will be communicated before the commencement of the classes.</h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        
        {/* FAQ 6 */}
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' ,marginTop:'30px'}}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>What will be the days of sessions?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>The classes for the starting batches will be conducted over the weekend (Saturday and Sunday). As we add more batches, we will work out the timings on weekdays too keeping in mind the availability of students. </h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        
        {/* FAQ 7 */}
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' ,marginTop:'30px' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>What if I miss some sessions? Will I be allowed to attend those sessions in other batches?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>We will try our best to make arrangements for the missed session(s) however we cannot promise the same. Students are encouraged to not miss the sessions from their batch.</h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        
        {/* FAQ 8 */}
        <div style={{ display: 'flex', alignItems: 'flex-start' , marginBottom: '30px' ,marginTop:'30px'}}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>I am residing outside India. How can I be part of the Program?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>We are currently providing limited slots for US, EU and South America time zones. Please write to us at <a href="mailto:creativekidsworld777@gmail.com">Send Email</a>. We will be happy to assist and identify some convenient slots.</h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        {/* FAQ 9 */}
<div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px', marginTop: '30px' }}>
  <div style={{ width: '40%', textAlign: 'left' }}>
    <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>My child is in 5th Grade, can he join the program?</h3>
  </div>
  <div style={{ width: '5%' }}></div>
  <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
    <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Please write to us, we would love to explore and discuss on the possibility.</h3>
  </div>
</div>
<hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />

{/* FAQ 10 */}
<div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px', marginTop: '30px' }}>
  <div style={{ width: '40%', textAlign: 'left' }}>
    <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Is there a refund policy?</h3>
  </div>
  <div style={{ width: '5%' }}></div>
  <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
    <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Yes, we have a transparent refund policy, Students can opt out and apply for refunds before the commencement of their 3rd session. Net amount for 8 classes will be refunded back on a pro-rata basis. No questions asked. No refunds will be made if student opts out of this program anywhere between 3rd and last session. </h3>
  </div>
</div>
<hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />

{/* FAQ 11 */}
<div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px', marginTop: '30px' }}>
  <div style={{ width: '40%', textAlign: 'left' }}>
    <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>How do I pay for the Program?</h3>
  </div>
  <div style={{ width: '5%' }}></div>
  <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
    <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Payment can be made securely online through our website using credit/debit cards or other available payment options.</h3>
  </div>
</div>
<hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />

{/* FAQ 12 */}
<div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px', marginTop: '30px' }}>
  <div style={{ width: '40%', textAlign: 'left' }}>
    <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Can I interact with the instructor during the sessions?</h3>
  </div>
  <div style={{ width: '5%' }}></div>
  <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
    <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Yes, of course the session is designed to be interactive where major learning happens through discussions, dialogues, presentation and activities.

</h3>
  </div>
</div>
<hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />

{/* FAQ 13 */}
<div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px', marginTop: '30px' }}>
  <div style={{ width: '40%', textAlign: 'left' }}>
    <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>How can I access the online sessions?</h3>
  </div>
  <div style={{ width: '5%' }}></div>
  <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
    <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>You will receive access details and links prior to start of each session via email and whatsapp.</h3>
  </div>
</div>
{/* <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} /> */}

      </div>
    </div>
	<Footer/>
    </div>
    
  );
}

export default EntrepreneurshipClass;
